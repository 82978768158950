<template>
  <div class="dayi_warp">
    <div class="back">
      <img src="@/static/back.png"
           @click="back"
           alt="">
      <div @click="add">
        发帖
      </div>
    </div>
    <div class="subjects">
      <div class="item_wrap"
           v-for="item in subjectList"
           :key="item.board_id">
        <div class="item"
             @click="checkSubject(item)">
          <div :class="{'active1': discuss_board == item.board_id,'icon':true}">
            <img :src="require('@/static/subject/'+images[item.board_id].img+'.png')"
                 alt="">
          </div>
          <div class="text">
            {{item.board_name}}
          </div>
        </div>
      </div>
    </div>
    <div class="status_line">
      <div :class="{'status':true,'active':status == item.id}"
           v-for="item in statusList"
           :key="item.label"
           @click="changeStatus(item)">{{item.label}}
      </div>
      <img src="@/static/subject/icon-搜索@2x.png"
           @click="search"
           alt="">
    </div>
    <div class="list">
      <!-- v-for="item in List" -->
      <div class="post_item"
           v-for='item in showList'
           @click="toInfo(item)"
           :key="item.discuss_id">
        <div class="head">
          <span>
            {{item.discuss_title}}
          </span>
          <div class="tab">
            {{item.board_name}}
          </div>
        </div>
        <div class="content">
          {{item.discuss_content}}
        </div>
        <div class="image"
             v-if="item.discuss_image &&JSON.parse(item.discuss_image).length">
          <img :src="item.discuss_image && JSON.parse(item.discuss_image)[0]"
               alt="">
        </div>
        <div class="opertion">
          <div class="left">
            <img src="@/static/dayi/icon-点赞@2x.png"
                 alt="">
            {{item.discuss_upvote_times}}
            <img src="@/static/dayi/信息.png"
                 style="margin-left:25px"
                 alt="">
            {{item.discuss_re_times}}
          </div>
          <div style="margin-right:15px">
            <!-- 编号：69162 2021-07-10 -->
            {{item.discuss_time}}
          </div>
        </div>
      </div>

    </div>
    <div class='page'>
      <a-pagination :current="current1"
                    :pageSize="pageSize1"
                    show-size-changer
                    :total="totalEle"
                    show-quick-jumper
                    @change="onShowSizeChange"
                    :show-total="total => `总共 ${totalEle} 条`"
                    @showSizeChange="onShowSizeChange" />
    </div>
    <!-- 发帖弹出框 -->
    <Form ref="FormRef"
          type='1'
          v-if="active"
          :subjectList='subjectList'
          @submit="submit"
          @cancel='cancel' />
    <!--搜索弹出框  -->
    <formDialog ref="formDialogRef"
                @getData='getList'
                v-else />

  </div>
</template>

<script setup>
import formDialog from '@/views/components/COMMON_TOP_SEARCH/search.vue'
import Form from './components/form.vue'
import { message } from 'ant-design-vue'
import { useRouter, onBeforeRouteLeave } from 'vue-router'
import { images, statusList } from './property'
import { nextTick, onUnmounted, ref, watch, onMounted } from 'vue'
import { getDiscussBoard, getDiscussList, addPost } from '@/api/post.js'

// let idx = ref('')
//let weishenhe = ref(true)
let router = useRouter()
let totalEle = ref(0)

let keywords = ref('')
let subjectList = ref([])
let status = ref(0)
let discuss_board = ref('')
let List = ref([])
let showList = ref([])

const pageSize1 = ref(10);//每页条数
const current1 = ref(1);//当前页数

const onShowSizeChange = (current, pageSize) => {
  let start = pageSize * (current - 1)
  let end = pageSize * current
  current1.value = current
  pageSize1.value = pageSize
  showList.value = List.value.slice(start, end)
};

// 判断哪个弹出框显示
let active = ref(false)

let formDialogRef = ref()
let FormRef = ref()


// 获取帖子内容板块
let getSubject = async () => {
  const { data } = await getDiscussBoard()
  subjectList.value = data
  if (window.sessionStorage.getItem('postBoard')) {
    discuss_board.value = window.sessionStorage.getItem('postBoard')
  }
  else { discuss_board.value = data[0].board_id }

  getList()
}

//获取帖子数据
let getList = async (keyword) => {
  List.value = []
  keywords.value = keyword
  const { data } = await getDiscussList({
    discuss_board: discuss_board.value,
    discuss_post_type: status.value,
    search: keywords.value
  })
  keywords.value = ''
  List.value = data.volist
  totalEle.value = data.volist.length
  onShowSizeChange(current1.value, pageSize1.value)
}
let checkSubject = (item) => {
  discuss_board.value = item.board_id
  current1.value = 1
  getList()
}
let back = () => {
  router.go(-1)
}

let changeStatus = (item) => {
  status.value = item.id
  current1.value = 1
  getList()
}
//搜索按钮，出现弹窗
let search = () => {
  active.value = false
  nextTick(() => {
    formDialogRef.value.keywords = keywords.value
    formDialogRef.value.dialogVisible = true
  })
}
//点击帖子条目跳转
let toInfo = (item) => {
  let form = {
    status: status.value
  }
  if (status.value == 2) {
    form.discuss_id = item.discuss_parent_id
  } else {
    form.discuss_id = item.discuss_id
  }
  router.push('/dayi/info?form=' + JSON.stringify(form))
}
//点击发帖
let add = () => {
  let form = {
    discuss_title: '',
    discuss_content: '',
    board_id: discuss_board.value,
    question_id: '',
    discuss_image: []
  }
  active.value = true
  nextTick(() => {
    FormRef.value.form = form
    FormRef.value.dialogVisible = true
  })
}

//发帖弹窗》提交
const submit = async () => {
  const { status } = await addPost(
    FormRef.value.form1
  )
  // console.log(FormRef.value.form1)
  message.success('发帖成功')
  cancel()
  getList()
}
//发帖弹窗》取消
const cancel = () => {
  FormRef.value.dialogVisible = false
}

onMounted(() => {
  getSubject()

})
onBeforeRouteLeave((to, from, next) => {
  if (to.name == '/dayi/info') {
    window.sessionStorage.setItem('postBoard', discuss_board.value)
  } else {
    window.sessionStorage.removeItem('postBoard')
  }
  next()
})


</script>

<style lang="scss" scoped>
.dayi_warp {
  width: 100%;
  height: 100vh;

  overflow-x: hidden;
  background-image: url("../../static/allBackg/dayi.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.back {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30rem;
  padding: 0 40rem;
  img {
    width: 60rem;
    height: 60rem;
    cursor: pointer;
  }
  div {
    background-color: #3e8ef3;
    border-radius: 35rem;
    font-size: 32rem;
    font-weight: 700;
    padding: 6rem 43rem;
    background: #1372e9;
    color: white;
    cursor: pointer;
  }
}

.subjects {
  display: flex;
  display: flex;
  padding-bottom: 20rem;
  padding-top: 40rem;
  overflow: auto;
  // margin-top: 40rem;
  background: #3fa2ff;
  // justify-content: center;
  .item_wrap {
    margin: 0 auto;
    padding: 0 20rem;
  }
  .item {
    cursor: pointer;
    .icon {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 110rem;
      height: 110rem;
      border: 10rem solid transparent;
      margin: 0 auto;
      border-radius: 50%;
      background: #ffffff;
      &:hover {
        border: 10rem solid #b7dcff;
      }
      img {
        width: 60rem;
        height: 60rem;
      }
    }
    .text {
      text-align: center;
      font-weight: bold;
      color: #ffffff;
      font-size: 30rem;
      margin-top: 10rem;
      flex-shrink: 0;
      margin-top: 5px;
    }
  }
  .active1 {
    border: 10rem solid #b7dcff !important;
  }
}
.status_line {
  display: flex;
  justify-content: center;
  // margin-top: 15rem;
  padding-top: 20rem;
  position: relative;
  padding-bottom: 15rem;
  background: #e3f1ff;
  .status {
    padding: 0 78rem;
    font-size: 28rem;
    font-weight: 500;
    color: #50a9fc;
    position: relative;
    cursor: pointer;
  }
  img {
    position: absolute;
    right: 40rem;
    width: 36rem;
    height: 36rem;
  }
}
.active {
  font-weight: bold !important;
  color: #1372e9 !important;
  &:after {
    content: "";
    position: absolute;
    width: 100rem;
    height: 5rem;
    background: #1372e9;
    left: 50%;
    margin-left: -50rem;
    top: 55rem;
    border-radius: 3rem;
  }
}
.list {
  width: 100%;
  padding: 0 40rem;
  margin: 0 auto;
  // height: calc(100% - 455rem);
  overflow: scroll;
  background: #e3f1ff;
  .post_item {
    border: 5rem solid #3fa2ff;
    text-indent: 15px;
    border-radius: 20rem;
    background: white;
    margin-top: 30rem;
    padding: 15rem;
    font-size: 24rem;
    position: relative;
    cursor: pointer;
    .head {
      position: relative;
      display: flex;
      span {
        font-weight: bold;
        color: #000000;
      }
    }
    .tab {
      background: #ebf3ff;
      border-radius: 5rem;
      font-weight: bold;
      color: #2196f3;
      position: absolute;
      right: 0rem;
      text-indent: 0;
      padding: 6rem 21rem;
    }
    .content {
      font-weight: 500;
      color: #333333;
      margin-top: 5px;
    }
    /* .image {
      width: 64px;
      height: 30px;
      img {
        width: 64px;
        height: 48px;
        position: absolute;
        right: 20px;
        top: 40px;
      }
    } */
    .opertion {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 28rem;
      font-weight: 500;
      color: #666666;
      margin-top: 32rem;
      img {
        width: 24rem;
        height: 24rem;
      }
      .left {
        display: flex;
        align-items: center;
        margin-left: 18px;
      }
    }
  }
}
.page {
  margin-left: 40rem;
  // margin-bottom: 78rem;
  // margin-top: 30rem;
  margin-top: 15rem;
  margin-bottom: 20rem;
}
:deep .ant-modal-content {
  overflow-x: inherit;
}
</style>