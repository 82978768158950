export const images = {
  1: { img: 'icon-语文@2x' },
  2: { img: 'icon-数学@2x' },
  3: { img: 'icon-英语@2x' },
  4: { img: 'icon-物理@2x' },
  5: { img: 'icon-化学@2x' },
  6: { img: 'icon-历史@2x' },
  7: { img: 'icon-政治@2x' },
  9: { img: 'icon-生物@2x' },
  10: { img: 'icon-地理@2x' },
  11: { img: 'icon-服务区@2x' }
}

export let statusList = ([
  { id: 0, label: '全部帖子' },
  { id: 1, label: '我的帖子' },
  { id: 2, label: '我的回复' },
  { id: 3, label: '我的点赞' },
])